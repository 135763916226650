import React from 'react';

import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { getPath } from '../../App';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import customCss from './blog.module.css';

const styles = () => ({

  card: {
    margin: '0.2em',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    flexDirection: 'column',
    ['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'row',
    }
  },
  content: {
    flex: 'grow',
    // background: 'blue',
    ['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
      width: '67%',
      // background: 'red',
    }

  },
  title: {
    fontSize: '1.4em',
    margin: '0em 0em 0em 0em',
    ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: 'inherit',      
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cover: {
    display: 'none',
    ['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
      display: 'inherit',
      width: '33%',
      // background: 'red',
    }

  },
});

const BookCard = (props) => {
  const { classes, even } = props;
  const content = (<CardContent className={classes.content}>
    <Typography variant="h4" className={classes.title}>
      {props.title}
    </Typography>
    <Typography style={{fontSize: '1.2em'}} className={customCss.Desc}>
      {props.desc}
    </Typography>
    <ReactMarkdown   >{props.body}</ReactMarkdown>
  </CardContent>);

  const media = props.hero ? (<CardMedia className={classes.cover} image={getPath( props.hero)} title={props.title} />) : null;

  return (<Card className={classes.card}>
    {even ? media : content}
    {even ? content : media}
  </Card>);
}

BookCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(BookCard, styles);