import { publicServer } from '../../utils/backend';

import React, { Component } from 'react';
import BookCard from '../../components/blog/BookCard';
import pages from './DefaultContent';

export class OnePage extends Component {

    state = {
        currentId: "none", 
        loadedPost: null,
    }

    componentDidMount() {
        this.loadData();
        // console.log("### ComponentDidMount: "+this.props.postId)
    }

    componentDidUpdate() {
        this.loadData();
        // console.log("### ComponentDidUpdate: "+this.props.postId)
    }

    loadData() {

        let newState = {
            currentId: this.props.postId,
            loadedPost: null
        }
        // We must insure that the post is updated when we change page
        let doLoad = this.props.postId && this.props.postId !== this.state.currentId

        if (!this.state.loadedPost || doLoad) {
            let path = '/posts/' + this.props.postId; // page-d-accueil'
            publicServer.get(path).then(response => {
                let currPost = response.data.post ?
                    response.data.post : pages[this.props.postId];
                newState.loadedPost = currPost
                this.setState(newState);
            });
        }
    }

    render() {
        let post = this.state.loadedPost;
        if (!post) {
            // Expected before the first load
            // console.log("### No post found returning an empty page")
            return null;
        // } else {
        //     console.log("### Now we get a post, about to render")
        //     console.log(post)
        }        
        return (<BookCard title={post.title} body={post.body} hero={post.hero} even={true} />);
    }
}

export default OnePage;