
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import * as actions from '../../store/actions/index';

class Login extends React.Component {
    
    componentDidMount () {
        this.props.onLogin();
    }

    render () {
        return <Navigate to="/"/>;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: () => dispatch(actions.login())
    };
};

export default connect(null, mapDispatchToProps)(Login);

// TODO - Renable user pwd directly from vitrnx ?  

// import React from 'react';
// import { connect } from 'react-redux';
// // import { NavLink } from 'react-router-dom';

// import * as actions from '../../store/actions/index';

// import classes from './auth.module.css';

// import { AppBar, Button, Grid, TextField, Typography } from '@mui/core';

// class Login extends React.Component {
//     state = {
//         email: '',
//         password: '',
//         isSignup: false
//     }

//     componentDidMount() {
//         if (this.props.authRedirectPath !== '/') {
//             this.props.onSetAuthRedirectPath();
//         }
//     }

//     submitHandler = (event) => {
//         event.preventDefault();
//         this.props.onAuth(this.state.email, this.state.password);
//     }

//     handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             this.submitHandler(event)
//         }
//     }
//     handleChange = name => event => {
//         this.setState({
//             [name]: event.target.value,
//         });
//     };

//     render() {

//         let msg = null;
//         if (this.props.error) {
//             if ('INVALID_EMAIL' === this.props.error.message)
//                 msg = "Could not login.<br />Are you sure your caps lock is not engaged?";
//             else
//                 msg = "Something went wrong, cause:" + this.props.error.message;
//         }

//         const errMsg = msg === null ? null : (<Typography variant="body1" style={{ color: 'var(--global-highlight-color)' }}>{msg}</Typography>);

//         return (
//             <div className={classes.Container}>
//                 <div className={classes.AnonBody}>
//                     <Grid className={classes.FormBox}
//                         container
//                         alignItems="center"
//                         direction="column">
//                         <Grid className={classes.AuthForm} >
//                             <AppBar position="static" color="secondary" >
//                                 <Typography
//                                     className={classes.FormHeader}
//                                     height="60"
//                                     variant="h6"
//                                     color="inherit" >
//                                     Please log in
//                                         </Typography>
//                             </AppBar>
//                             <Grid className={classes.Form}
//                                 container
//                                 alignItems="center"
//                                 direction="column"
//                                 justify="center">
//                                 {errMsg}
//                                 <TextField
//                                     id="email"
//                                     label="Email"
//                                     margin="normal"
//                                     onChange={this.handleChange('email')}
//                                     onKeyPress={this.handleKeyPress}
//                                 />
//                                 <TextField
//                                     id="password"
//                                     type="password"
//                                     helperText="Enter your Password"
//                                     label="Password"
//                                     margin="normal"
//                                     onChange={this.handleChange('password')}
//                                     onKeyPress={this.handleKeyPress}
//                                 />

//                                 <Button
//                                     label="Submit"
//                                     variant="contained"
//                                     color="secondary"
//                                     aria-label="submit"
//                                     style={{ margin: 15 }}
//                                     onClick={(event) => this.submitHandler(event)} >
//                                     SUBMIT
//                                     </Button>
//                             </Grid>
//                         </Grid>
//                         {/* <Grid >
//                                 <div className={classes.FormTip} style={{ margin: 15, fontsize: '0.8em' }}>
//                                     No account yet? Please  <NavLink to="/register" >Register</NavLink>
//                                 </div>
//                             </Grid> */}
//                     </Grid>
//                 </div>
//             </div>
//         );
//     }
// }

// const mapStateToProps = state => {
//     return {
//         loading: state.auth.loading,
//         error: state.auth.error,
//         isAuthenticated: state.auth.token !== null,
//         authRedirectPath: state.auth.authRedirectPath
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         // onAuth: (email, password) => dispatch(actions.fbAuth(email, password)),
//         // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
//     };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Login);
