import React, { Component } from 'react';
import { Routes, Route} from 'react-router-dom';
import { connect } from 'react-redux';

// Vitrnx generic components
import CallbackPage from '../../components/navigation/Callback'
import { ListByCategory, OnePost, OpenPostEditor } from '../../containers/blog/Blog'
import Users from '../../containers/users/Users'

// Upala specific 
import Layout from '../Layout'
import { OnePage } from './OnePage'
import Dashboard from '../../containers/dashboard/Dashboard'
import Register from '../../containers/auth/Register'
import Login from '../../containers/auth/Login'
import Logout from '../../containers/auth/Logout'

// Well known static pages
const home = "page-d-accueil";
const impress = "mentions-legales"
const workshop = "atelier"
const bio = "biographie"
const contact = "contact"

class UpalaRoutes extends Component {

  render() {
    const { isAuth, userRoles } = this.props

    // Default routes 
    let routes = [
      <Route key="callback" path="/callback" element={<CallbackPage />} />,
      // Static pages
      <Route key="accueil" path="/s/page-d-accueil" element={<OnePage postId={home} />} />,
      <Route key="imprint" path="/s/mentions-legales" element={<OnePage postId={impress} />} />,
      <Route key="workshop" path="/s/atelier" element={<OnePage postId={workshop} />} />,
      <Route key="bio" path="/s/biographie" element={<OnePage postId={bio} />} />,
      <Route key="contact" path="/s/contact" element={<OnePage postId={contact} />} />,
      <Route key="home" path="/" element={<OnePage postId={home} />} />,
      // Lists by tags
      <Route key="query" path="/q/:id" element={<ListByCategory {...this.props} listType="card" />} />,
      <Route key="books" path="/r/:id" element={<ListByCategory {...this.props} listType="book" />} />,
      // Single Post page
      <Route key="one-post" path="/p/:id" element={<OnePost />} />,
    ];

    // Login management
    if (isAuth) {
      routes = [...routes, (<Route key="logout" path="/logout" element={<Logout />} />)];
    } else {
      routes = [
        ...routes,
        (<Route key="register" path="/register" element={<Register />} />),
        (<Route key="login" path="/login" element={<Login />} />)
      ];
    }

    // Private routes
    if (userRoles && (userRoles.includes("ADMIN") || userRoles.includes("EDITOR"))) {
      routes = [...routes,
        <Route key="all-posts" path="/all" element={<ListByCategory {...this.props} listType="list" />} />,
        <Route key="edit-new" path="/p/edit" element={<OpenPostEditor />} />,
        <Route key="edit-one" path="/p/edit/:id" element={<OpenPostEditor />} />,
      ];
    }
    if (userRoles && (userRoles.includes("ADMIN") || userRoles.includes("USER_ADMIN"))) {
      routes = [
        ...routes,
        (<Route key="admin-dashboard" path="/dashboard" element={<Dashboard />} />),
        (<Route key="list-users" path="/u/" element={<Users />} />)
      ]
    }

    console.log("After creating route:", ...routes)

    return (<Layout><Routes>
      {[...routes]}
    </Routes></Layout>);
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.oidc.user != null,
    userRoles: state.auth.userRoles,
  };
};

export default connect(mapStateToProps)(UpalaRoutes);

