import React from 'react';
import NavigationItem from './NavigationItem';

import classNames from 'classnames';

export default class NavigationItems extends React.Component {

    render() {
        const { className, navItems } = this.props;

        if (!navItems) return null;

        let links = navItems.map(
            item => (<NavigationItem key={item.url} link={item.url}>{item.label}</NavigationItem>)
        );

        return (
            <ul className={classNames(className)}>
                {links}
            </ul>
        );
    }
}

