import React from 'react';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { getPost, createPost, savePost, deletePost } from '../../store/actions/post'
import apiServer from '../../utils/backend';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { Field, Form } from 'react-final-form'
import { Button, Typography } from '@mui/material';
import TagSelect from '../../components/ui/Select/TagSelect'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Inline styles 
// const divPadding = { width: '100%', padding: '0.2em 0.5em', background: 'inherit', margin: '.2em' };
const divPadding = { width: '100%', padding: '0.2em 1em', background: 'inherit', margin: '.2em' };
const buttonDiv = { display: 'flex', flexDirection: 'row', padding: '0.5em 1em', textAlign: 'center' };
const labelStyle = { textAlign: 'left', margin: '0em .2em', border: '0px solid' };
const fieldStyle = { textAlign: 'left', padding: '0.2em 1em', border: '1px solid grey' };
const btn = { width: '8em', padding: '.4em 0.6em', margin: '0em 1em' }
// const input = { width: '100%', border: '0px solid' };

const datePicker = ({ input, label, type, className, selected, meta: { touched, error } }) => (
    <div style={divPadding}>
        <label style={labelStyle} >{label + ": " + moment(input.value).format("DD/MM/YYYY")} - Click to change</label>
        <DatePicker {...input}
            //selected={selected ? moment(selected) : null}
            style={fieldStyle}
            type={type}
            className={className}
            peekNextMonth
            //dateForm="YYYY-MM-DDTHH:mm:ssZ"
            dateForm="DD/MM/YYYYT12:00:00Z"
            // dateFormat={"YYYY"}
            // showMonthDropdown
            // showYearDropdown
            dropdownMode="select"
        />
        {touched && error && <span className="error_field">{error}</span>}
    </div>
)

class NewFormComp extends React.Component {

    state = {
        isNew: true,
        needsRefresh: false,
        initialValues: null,
    }

    reloadIfNecessary() {
        if (this.state.needsRefresh) {
            console.log('#### Launching post retrieval')
            console.log(this.state.needsRefresh)
            this.state.prevId === "" ? this.props.onCreate() : this.props.onOpen(this.state.prevId);
        }
    }

    componentDidMount() { this.reloadIfNecessary() }

    deleteHandler = () => {
        if (window.confirm('Are you sure you want to completely remove this post?')) {
            if (this.props.initialValues.id && this.props.initialValues.id !== "") {
                this.props.onDelete(this.props.initialValues.slug)
                this.props.onClick("/");
            } else
                // No ID <=> new post, simply forward to cancel
                this.cancelHandler()
        }
    }

    cancelHandler = () => {
        this.props.onBack();
    }

    canDelete = () => {
        let post = this.props.initialValues
        let canDelete = false
        if (post && post.slug) {
            canDelete = this.props.userRoles.includes("MODERATOR") || this.props.userRoles.includes("ADMIN") || this.props.userId === post.authorId
        }
        return canDelete
    }

    static getDerivedStateFromProps(props, state) {
        let currId = ""
        if (props.postId) {
            currId = props.postId
        }

        // special case for the first time we pass here, 
        // to simplify the creation case where prev id is empty
        if (state.isNew || currId !== state.prevId) {

            console.log("### In NewForm.getDerivedStateFromProps, props:")
            console.log(props)
            console.log("state:")
            console.log(state)

            return {
                needsRefresh: true,
                isNew: false,
                prevId: currId,
            }
        }

        console.log(state.initialValues)
        console.log(props)

        if (!state.initialValues && props.initialValues) {
            return {
                needsRefresh: false,
                initialValues: props.initialValues,
            }
        }

        // No state update necessary
        return null
    }

    render() {
        return (
            <div >
                {/* <Typography>
                    This is a new test
                </Typography> */}
                <Form
                    initialValues={this.state.initialValues}
                    onSubmit={values => {
                        this.props.onSubmit(values)
                        this.setState({ isNew: false })
                    }}
                    validate={values => {
                        // do validation here, and return errors object
                    }}
                >
                    {({ handleSubmit, pristine, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={buttonDiv}>
                                <Button style={btn} label="Save" disabled={pristine || submitting} variant="contained" color="primary" aria-label="save" type="submit"> Save </Button>
                                <Button style={btn} label="Reset" disabled={pristine || submitting} variant="contained" color="secondary" aria-label="reset" type="button" onClick={form.reset}> Reset </Button>
                                <Button style={btn} label="Cancel" disabled={submitting} variant="contained" color="secondary" aria-label="cancel" type="button" onClick={(event) => { this.cancelHandler(event) }}> Cancel </Button>
                                <Button style={btn} label="Delete" disabled={submitting} variant="contained" color="secondary" aria-label="delete" type="button" onClick={(event) => { this.deleteHandler(event) }}> Delete post </Button>
                            </div>
                            <div style={divPadding}>
                                <label style={labelStyle}>Slug</label>
                                <Field
                                    style={fieldStyle}
                                    name="slug"
                                    component="input"
                                    type="text"
                                    placeholder="a-path-to-your-post"
                                />
                            </div>
                            <div style={divPadding}>
                                <label style={labelStyle}>Title</label>
                                <Field
                                    style={fieldStyle}
                                    name="title"
                                    component="input"
                                    type="text"
                                    placeholder="A nice title for your post"
                                />
                            </div>
                            <Field
                                name="publishedAt"
                                component={datePicker}
                                type="button"
                                label="Publication Date"
                                selected={values.publishedAt}
                                //defaultValue={this.props.initialValues ? this.props.initialValues.publishedAt : null}
                                // onChange={this.handleChange.bind(this)}
                                className="form-control"
                            />
                            <div style={divPadding}>
                                <label style={labelStyle}>Thumbnail image</label>
                                <Field
                                    style={fieldStyle}
                                    name="thumb"
                                    component="input"
                                    type="text"
                                    placeholder="A small image (320x240px) for the lists"
                                />
                            </div>
                            <div style={divPadding}>
                                <label style={labelStyle}>Hero image</label>
                                <Field
                                    style={fieldStyle}
                                    name="hero"
                                    component="input"
                                    type="text"
                                    placeholder="A large image (800x200px) for the header"
                                />
                            </div>
                            <div style={{...divPadding, display: 'flex', flexDirection: 'row'}}>
                                <label style={labelStyle}>tags</label>
                                <Field
                                    name="tags"
                                    component={TagSelect}
                                />
                            </div>
                            <div style={{...divPadding, display: 'flex', flexDirection: 'column'}}>
                                <label style={{...labelStyle}} htmlFor="desc">Abstract</label>
                                <Field
                                    name="desc"
                                    component="textarea"
                                    placeholder="This is shown as extract"
                                    style={{...divPadding, width: '90%', minHeight: '4em', padding: '0.4em' }} />
                            </div>
                            <div style={{...divPadding, display: 'flex', flexDirection: 'column'}}>
                                <label style={labelStyle} htmlFor="body">Content</label>
                                <Field
                                    name="body"
                                    component="textarea"
                                    placeholder="Express yourself here!"
                                    style={{ width: '90%', minHeight: '40em', padding: '0.4em' }} />
                            </div>
                            <div style={buttonDiv}>
                                <Button style={btn} label="Save" disabled={pristine || submitting} variant="contained" color="primary" aria-label="save" type="submit"> Save </Button>
                                <Button style={btn} label="Reset" disabled={pristine || submitting} variant="contained" color="secondary" aria-label="reset" type="button" onClick={form.reset}> Reset </Button>
                                <Button style={btn} label="Cancel" disabled={submitting} variant="contained" color="secondary" aria-label="cancel" type="button" onClick={(event) => { this.cancelHandler(event) }}> Cancel </Button>
                                <Button style={btn} label="Delete" disabled={submitting} variant="contained" color="secondary" aria-label="delete" type="button" onClick={(event) => { this.deleteHandler(event) }}> Delete post </Button>
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("### In mapStateToProps")
    console.log(state)
    return {
        initialValues: state.post.post,
        userId: state.auth.userId,
        userRoles: state.auth.userRoles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreate: () => dispatch(createPost()),
        onOpen: (slug) => {
            console.log("### About to Open for " + slug)
            dispatch(getPost(slug, true))
        },
        onSubmit: (post) => {
            console.log("### About to submit")
            console.log(post)
            dispatch(savePost(post))
        },
        onDelete: (slug) => dispatch(deletePost(slug)),
    }
}

const connectedNewForm = connect(mapStateToProps, mapDispatchToProps)(NewFormComp)
export default withErrorHandler(connectedNewForm, apiServer);
