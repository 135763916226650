import { USER_FOUND, USER_EXPIRED } from "redux-oidc-2";
import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT, SET_AUTH_REDIRECT_PATH } from '../actions/actionTypes';
import { loadVitrnxUserMeta } from '../actions/auth'
import { updateObject } from '../utility';

// import { 
//     USER_EXPIRED, 
//     REDIRECT_SUCCESS, 
//     USER_LOADED, 
//     SILENT_RENEW_ERROR, 
//     SESSION_TERMINATED, 
//     USER_EXPIRING, 
//     USER_FOUND, LOADING_USER, 
//     LOAD_USER_ERROR, 
//     USER_SIGNED_OUT } from "redux-oidc";
    // idToken: null,
    // refreshToken: null,
    // userId: null,

const initialState = {
    email: 'anonymous',
    displayName: 'Anonymous',
    userRoles: [], // Arrays in redux must be initialised to an empty array rather than null.

    authRedirectPath: '/',
    loading: false,
    error: null
};

const authStart = (state, action) => {
    return updateObject(state, { loading: true, error: null });
};

const userLoaded = (state, action) => {
    return updateObject(state, loadVitrnxUserMeta(
        action.payload.id_token,
        action.payload.refresh_token,
        action.payload.profile.name,
    ));
};

const userExpired = (state, action) => {
    console.log('userExpired, action:', action)
    return state;
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        idToken: action.idToken,
        refreshToken: action.refreshToken,
        userId: action.userId,
        email: action.email,
        displayName: action.displayName,
        userRoles: action.userRoles,
        loading: false,
        error: null
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        email: 'anonymous',
        displayName: 'anonymous',
        userRoles: [],
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        refreshToken: null,
        userId: null,
        email: 'anonymous',
        displayName: 'Anonymous',
        userRoles: [],
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case USER_FOUND: return userLoaded(state, action);
        case USER_EXPIRED: return userExpired(state, action);
        case AUTH_START: return authStart(state, action);
        case AUTH_SUCCESS: return authSuccess(state, action);
        case AUTH_FAIL: return authFail(state, action);
        case AUTH_LOGOUT: return authLogout(state, action);
        case SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        default:
            return state;
    }
};

export default reducer;