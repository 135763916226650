import axios from 'axios';

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const publicServer = axios.create({
    baseURL: backendUrl + '/pub',
 });

const apiServer = axios.create({
    baseURL: backendUrl + '/api',
});
  
export default apiServer
