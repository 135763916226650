
import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT, SET_AUTH_REDIRECT_PATH } from './actionTypes';

import apiServer from '../../utils/backend';
import userManager from '../../utils/userManager';

export const authStart = () => {
    return {
        type: AUTH_START
    };
};

export const userMeta = (idToken, refreshToken, localId) => {
    return dispatch => {
        const authData = {
        };

        let url = 'auth/login';
        var options = { headers: { 'Authorization': idToken } };
        apiServer.post(url, authData, options)
            .then(response => {
                dispatch(authSuccess(idToken, refreshToken, localId, response.data.userMeta.email, response.data.userMeta.displayName, response.data.userMeta.userRoles));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail('could not get vitrnx specific infos'));
            });
    };
};

export const authSuccess = (idToken, refreshToken, userId, email, displayName, userRoles) => {

    return {
        type: AUTH_SUCCESS,
        idToken: idToken,
        refreshToken: refreshToken,
        userId: userId,
        email: email,
        displayName: displayName,
        userRoles: userRoles
    };
};

export const loadVitrnxUserMeta = (idToken, refreshToken, userId) => {

    let userRoles = []
    // Hack for PoC
    if (userId === 'admin' || userId === 'bruno' || userId === 'mmsinou') {
        userRoles.push('ADMIN', 'EDITOR', 'MODERATOR', 'USER_MANAGER')
    }

    return {
        email: 'email@example.com',
        displayName: userId,
        userRoles: userRoles,
        loading: false,
    };

    // TODO finalise this
    // var options = { headers: { 'Authorization': idToken } };
    // apiServer.get('/users/' + userId, options).then(response => {
    //     //                 console.log('Auth success', response.data)
    //     //                 dispatch(updateLocalStorage(response.data.idToken, response.data.refreshToken, response.data.localId, response.data.expiresIn))
    //     //                 dispatch(registerAuthTimeout(response.data.refreshToken, response.data.expiresIn));

    //     //                 // dispatch(userMeta(response.data.idToken, response.data.refreshToken, response.data.localId));

    //     console.log(response); // Nothing to do
    // }).catch(err => {
    //     //                 dispatch(authFail(err.response.data.error));

    //     console.log(err); // fails silently
    // });


    // // , email, displayName, userRoles
    // return {
    //     type: AUTH_SUCCESS,
    //     idToken: idToken,
    //     refreshToken: refreshToken,
    //     userId: userId,
    //     email: 'email',
    //     displayName: 'displayName',
    //     userRoles: []
    // };
};

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error: error
    };
};

export const login = () => {
    userManager.signinRedirect();

    return {
        type: AUTH_START
    };
};


export const logout = () => {
    userManager.removeUser(); // removes the user data from sessionStorage

    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: AUTH_LOGOUT
    };
};

export const updateLocalStorage = (token, refreshToken, userId, expiresIn) => {
    return dispatch => {
        localStorage.setItem('idToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        const expirationDate = new Date(new Date().getTime() + (expiresIn - 300) * 1000);
        localStorage.setItem('expirationDate', expirationDate);
    };
};


export const registerAuthTimeout = (refreshToken, email, expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            console.log("Auth timout reached... something must have been done")
            // dispatch(refreshFbToken((expirationTime - 500) * 1000, email));
        }, 10 * 1000);
    };
    //         dispatch(logout());
};



// export const userCreate = (token, userId, name, email, address) => {
//     return dispatch => {
//         const data = {
//             userId: userId,
//             name: name,
//             email: email,
//             address: address
//         };

//         var options = { headers: { 'Authorization': token } };
//         axios.post('/users', data, options).then(response => {
//             console.log(response); // Nothing to do
//         }).catch(err => {
//             console.log(err); // fails silently
//         });
//     };
// };


// export const fbRegister = (name, email, password, address) => {
//     return dispatch => {
//         dispatch(authStart());
//         const authData = {
//             email: email,
//             password: password,
//             returnSecureToken: true
//         };

//         // let url = apiPrefix + 'signupNewUser?key=' + apiKey;
//         authServer.post("login", authData)
//             .then(response => {
//                 dispatch(updateLocalStorage(response.data.idToken, response.data.refreshToken, response.data.localId, response.data.expiresIn))
//                 dispatch(registerAuthTimeout(response.data.refreshToken, response.data.expiresIn));

//                 dispatch(userCreate(response.data.idToken, response.data.localId, name, email, address));
//                 dispatch(authSuccess(response.data.idToken, response.data.refreshToken, response.data.localId, email, name, ["GUEST"]));
//             })
//             .catch(err => {
//                 console.log(err);
//                 dispatch(authFail(err.response.data.error));
//             });
//     };
// };

// Try to login via Firebase. TODO directly to this from the Go Backend
// export const fbAuth = (email, password) => {
//     return dispatch => {
//         dispatch(authStart());
//         const authData = {
//             email: email,
//             password: password,
//             returnSecureToken: true
//         };

//         let url = apiPrefix + 'verifyPassword?key=' + apiKey;
//         axios.post(url, authData)
//             .then(response => {

//                 console.log('Auth success', response.data)
//                 dispatch(updateLocalStorage(response.data.idToken, response.data.refreshToken, response.data.localId, response.data.expiresIn))
//                 dispatch(registerAuthTimeout(response.data.refreshToken, response.data.expiresIn));

//                 dispatch(userMeta(response.data.idToken, response.data.refreshToken, response.data.localId));
//             })
//             .catch(err => {
//                 console.log(err);
//                 dispatch(authFail(err.response.data.error));
//             });
//     };
// };

// // Try to login via Pydio. TODO directly to this from the Go Backend. Really TODO?
// export const fbAuth = (email, password) => {
//     return dispatch => {
//         dispatch(authStart());
//         const authData = {
//             email: email,
//             password: password,
//             returnSecureToken: true
//         };

//         // let url = apiPrefix + 'signupNewUser?key=' + apiKey;
//         authServer.post("login", authData)
//             .then(response => {

//                 console.log('Auth success', response.data)
//                 dispatch(updateLocalStorage(response.data.idToken, response.data.refreshToken, response.data.localId, response.data.expiresIn))
//                 dispatch(registerAuthTimeout(response.data.refreshToken, response.data.expiresIn));

//                 // dispatch(userMeta(response.data.idToken, response.data.refreshToken, response.data.localId));
//             })
//             .catch(err => {
//                 console.log(err);
//                 dispatch(authFail(err.response.data.error));
//             });
//     };
// };


// export const refreshFbToken = (refreshToken) => {
//     return dispatch => {
//         if (!refreshToken || refreshToken.length < 5)
//             return; // Invalid refresh token, do nothing
//         const authData = {
//         };
//         var options = {};
//         let url = secureTokenApiPrefix + 'key=' + apiKey
//             + '&grant_type=refresh_token&refresh_token=' + refreshToken;

//         apiServer.post(url, authData, options)
//             .then(response => {
//                 dispatch(updateLocalStorage(response.data.id_token, response.data.refresh_token, response.data.user_id, response.data.expires_in))
//                 dispatch(registerAuthTimeout(response.data.refresh_token, response.data.expires_in));
//                 dispatch(userMeta(response.data.id_token, response.data.refresh_token, response.data.user_id));
//             })
//             .catch(err => {
//                 console.log(err);
//             });
//     };
// };


export const authCheckState = () => {

    // TODO: Also add refresh token mechanism
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(userMeta(token, userId));
            }
        }
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: SET_AUTH_REDIRECT_PATH,
        path: path
    };
};


