const urgentAndImportantColor = "#F44336";
const urgentColor = "#1A237E";
const importantColor = "#FF8F00";
const normalColor = "#2E7D32";


export {
    urgentAndImportantColor,
    urgentColor,
    importantColor,
    normalColor,
};
