// This should be the main entry point to retrieve configs that are not in the store 

const getConfig = () => {
    console.log('Retrieving config for:', process.env.REACT_APP_CLIENT_ID)
    console.log('Public URL', process.env.REACT_APP_PUBLIC_URL, 'Auth URL', process.env.REACT_APP_OIDC_AUTH_URL)

    return {
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/callback`,
        response_type: 'code',
        scope: 'openid email profile pydio offline',
        authority: `${process.env.REACT_APP_OIDC_AUTH_URL}/oidc`,
        automaticSilentRenew: true,
        silent_redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/silent_renew`,
        filterProtocolClaims: true,
        loadUserInfo: false
    };
}

export default getConfig;