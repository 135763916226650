import React from 'react';
import { connect } from 'react-redux';

import { CallbackComponent } from 'redux-oidc-2';

import userManager from "../../utils/userManager";

class CallbackPage extends React.Component {

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={() => {
                    console.log("Success!")
                    //this.props.history.push('/');
                }}
                errorCallback={error => {
                    console.log("Could not perform OAuth flow:", error);
                    //this.props.history.push('/');
                }}
            >
                {/* <div>Thanks for authenticating, you are about to be redirected...</div> */}
                <div>Merci de vous être autentifié, vous pouvez maintenant éditer le contenu.</div>
            </CallbackComponent>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(null, mapDispatchToProps)(CallbackPage);
