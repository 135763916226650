import { POST_CREATE, POST_READ, POST_UPDATE, POST_START_SAVING, POST_SAVE_SUCCESS, POST_SAVE_FAILURE, POST_STOP_EDITING } from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    // post: {},
    // isNew: false, 
    editing: false,
    dirty: false,
    loading: false,
    error: null
};

const postCreate = (state, action) => {
    let author = state.oidc && state.oidc.user ? state.oidc.user.profile.name : "";
    return updateObject(state, { post: {authorId: author }, isNew: true, editing: true, dirty: true, error: null });
};

const postRead = (state, action) => {
    // editing: false, // migth be true when opening for edit
    return updateObject(state, { post: action.post, editing: action.openForEdit, isNew: false,  dirty: false, error: null });
};

const postUpdate = (state, action) => {
    return updateObject(state, { post: action.post, editing: true, dirty: true, error: null });
};

const postStartSaving = (state, action) => {
    return updateObject(state, { loading: true });
};

const postSaveSucess = (state, action) => {
    return updateObject(state, { post: action.post, isNew: false, editing: false, dirty: false, loading: false, error: null });
};

const postSaveFailure = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const postStopEditing = (state, action) => {
    return updateObject(state, { dirty: false, editing: false, loading: false, error: null });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_CREATE: return postCreate(state, action);
        case POST_READ: return postRead(state, action);
        case POST_UPDATE: return postUpdate(state, action);
        case POST_START_SAVING: return postStartSaving(state, action);
        case POST_SAVE_SUCCESS: return postSaveSucess(state, action);
        case POST_SAVE_FAILURE: return postSaveFailure(state, action);
        case POST_STOP_EDITING: return postStopEditing(state, action);
        default:
            return state;
    }
};

export default reducer;
