import React from 'react';

import AuxWrapper from '../hoc/AuxWrapper/AuxWrapper'

import { topRightColor, bottomLeftColor } from '../constants'
import { Link, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';


import { Copyright } from '.'

import classNames from 'classnames';

const mail = "mailto:" + process.env.REACT_APP_ADMIN_EMAIL;

const styles = theme => ({
    root: {
        background: 'linear-gradient(to top right, ' + bottomLeftColor + ', ' + topRightColor + ')',
        padding: '0.8em 2em 0.4em 2em',
        textAlign: 'right',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'right'
    },

    custom: {
        color: 'inherit',
        padding: '0em 0.6em',
        fontWeight: '300',
    },
    grow: {
        flexGrow: 1,
    }
});

function footer(props) {
    const { classes, className } = props;
    return (
        <footer className={classNames(classes.root, className)}>
            {<AuxWrapper>
                <div className={classes.grow} />
                <Typography variant="body1" gutterBottom className={classes.custom}>{Copyright}</Typography>
                <Link href="/s/mentions-legales" variant="body1" className={classes.custom}>Mentions Légales</Link>
                <Link href={mail} variant="body1" className={classes.custom}>Contact</Link>
            </AuxWrapper> || 'class names'}
        </footer>
    );
}

export default withStyles(footer, styles);