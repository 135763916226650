import { POSTS_FETCH_START, POSTS_FETCH_SUCCESS, POSTS_FETCH_ERROR } from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    query: '',
    displayType: 'card',
    loading: false,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POSTS_FETCH_START: return fetchStart(state, action);
        case POSTS_FETCH_SUCCESS: return fetchSuccess(state, action);
        case POSTS_FETCH_ERROR: return fetchError(state, action);
        default:
            return state;
    }
};

const fetchStart = (state, action) => {
    return updateObject(state, { posts: {}, query: action.payload.query, displayType: action.payload.displayType, loading: true, error: null });
};

const fetchSuccess = (state, action) => {
    return updateObject(state, { posts: action.posts, loading: false, error: null });
};

const fetchError = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

export default reducer;
