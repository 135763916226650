import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PostEditor from './PostEditor';
import PostList from './PostList';

import Post from '../../components/blog/Post';

export function ListByCategory({ listType }) {
    let params = useParams();
    let navigate = useNavigate();
    function handleClick(relpath) {
        navigate(relpath);
    }
    return (<PostList tagId={params.id} listType={listType} onClick={handleClick} />)
}

export function OnePost() {
    const { id } = useParams();
    let navigate = useNavigate();
    function handleClick(relpath) {
        navigate(relpath);
    }
    function handleBack() {
        navigate(-1);
    }

    return (<Post postId={id} onClick={handleClick} onBack={handleBack} />);
}

export function OpenPostEditor() {

    const { id } = useParams();
    let navigate = useNavigate();
    function handleClick(relpath) {
        navigate(relpath);
    }
    function handleBack() {
        navigate(-1);
    }
    return (<PostEditor postId={id} onClick={handleClick} onBack={handleBack} />);
}
