

const UpalaDefaultPages = {
    "page-d-accueil": {
        title: "Rester en bon équilibre de santé est une occupation de tous les instants...",
        body: `**Docteur en médecine,** j'ai exercé comme médecin généraliste homéopathe pendant 30 ans,  
Complétant au fil du temps ma formation à la programmation neuro linguistique, l'hypnose, diverses médecines traditionnelles...  
Pas à pas, j'ai élargi ma vision de la santé.

**Quand on parle de santé, on imagine...**

Diètes diverses, soins, prise en charge, traitements médicamenteux, médecins, hôpital, sécurité sociale...   
Que sais-je encore ?

**Hors, la Santé est un Art ...**

Art de vivre...
Art de faire...
Art de communiquer...
Art de créer...
Art de cultiver la joie...
Art d'être...

## Au fil des jours et des pas

**Dans ce site**, au-delà de mes compétences à l'exercice de l'art médical,  j'ouvrirai grande la porte à d'autres chemins de bien-être.  
Ceux que je pratique : le dessin, la peinture, l'écriture, mais aussi la couture, le patchwork, la cuisine, le soin des êtres et de la planète...  

La liste n'est pas exhaustive !

**Le chemin de la santé passe par les échanges, le croisement des savoirs et savoir-faires**  
Je proposerai donc des liens vers d'autres sites et des articles venus d'ailleurs.
`

    },
    "mentions-legales": {
        title: "Mentions Légales",
        body: `
Sauf mention contraire, le contenu de ce site (textes, images...) est la propriété de Marie-Madeleine Sinou.

Aucune responsabilité ne pourra être engagée sur la mauvaise utilisation des conseils de santé, des réflexions de fond ou recettes de cuisine. Si vous avez le moindre doute, demandez l’avis de votre médecin traitant. Les conseils dispensés ici le sont à titre informatif pour des personnes sans pathologies particulières. Ne suivez pas ses conseils à la légère, notamment pour éviter toutes aggravation de pathologies existantes ou interactions médicamenteuses.

Chaque personne est unique, voilà pourquoi ce qui sera bon pour certaines personnes ne sera pas forcément bon pour vous. Prenez toujours l’avis d’un professionnel de santé.

## Crédits

Photo de la page d'accueil : Bernard Sinou.
`,

    },
    "atelier": {
        title: "Ateliers de peinture, ateliers de réflexion",
        desc: "En co-animation avec Anne-Noëlle Bissuel, artiste et art-thérapeute, nous mettons en commun nos compétences d'artiste et de thérapeute",
        body: `
Plus d'information sur les prochains ateliers bientôt.

N'hésitez pas à revenir.
`

    },
    "entretien": {
        title: "Entretien individuel",
        desc: "Prendre le temps et se poser. Ecouter... <br />L'histoire d'une maladie, d'une angoisse, de soucis familiaux ou professionnels.",
        body: `Entendre la parole dite, les questions posées et celles qui affleurent.

Avec bienveillance et respect, ensemble chercher à comprendre, à renouer les liens, à donner un sens à ce qui est dit et à ce qui a été vécu.

Accepter ce qui a été ou ce qui est aujourd'hui pour pouvoir envisager demain.  
Etablir ensemble un plan d'action :

- Comment faire ? Quoi faire ?
- Qui contacter peut-être ?
- Quelles solutions envisager ?

Puis mettre en place les nouveaux apprentissages dans le temps et les évaluer.

Concrètement :

- L'entretien dure une heure au minimum.
- La première rencontre permet de faire un état de la situation et d'envisager l'action à mener.
- Le nombre et le rythme des entretiens suivants sont déterminés d'un commun accord et sont très variables d'un sujet à l'autre.
`

    },
    "biographie": {
        title: "Biograhie",
        desc: "Marie-Madeleine Sinou, Docteur en Médecine.",
        body: `Entendre la parole dite, les questions posées et celles qui affleurent.

Telle est ma philosophie, avec bienveillance et respect.
`
    },
    "contact": {
        title: "Contact",
        desc: "",
        body: `
Les entretiens individuels ont lieu à Lyon dans le 5° arrondissement ou dans la Drôme des collines.

Les ateliers ont lieu à Lyon, dans la Drôme des collines ou selon les groupes constitués.

**Email** : [contact@unpasapreslautre.com](mailto:contact@unpasapreslautre.com)  

**Téléphone** : 06 11 28 38 45
`
    }

}

export default UpalaDefaultPages;
