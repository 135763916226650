import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { transition } from "./assets/jss/common.jsx";

// Provides default material UI css props to children components
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { lightGreen } from '@mui/material/colors';

import { highlightColor, textColor } from "./constants"

// **Hack here to switch to specific routes.**
import MyRoutes from './specific/upala/Routes'

export const filesRelativePath = process.env.REACT_APP_FILE_PATH;

// To access files
export function getPath(name) {
  return filesRelativePath+ "/images/" + name;
}

export function getDocPath(name) {
  return filesRelativePath+ "/docs/" + name;
}

// Global theme using Material UI
const theme = createTheme({

  root: {
    color: textColor
  },

  palette: {
    primary: lightGreen,
    secondary: { main: highlightColor },
  },

  typography: {
    color: textColor,
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Fira Sans"', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    fontWeightMedium: 400,

    h2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 350,
      color: textColor
    },
    body2: {
      fontWeight: 400,
      color: textColor
    },
    subtitle1: {
      color: textColor
    },
  },

  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },

  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: 'touch'
  },
});

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MyRoutes props={this.props} />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
  
}

export default App;
