export const Title = "Un pas après l'autre";
export const Subtitle = "Docteur Marie-Madeleine SINOU";
export const SubtitleShort = "Dr. Marie-Madeleine SINOU";
export const Copyright="(C) 2024. Marie-Madeleine Sinou";

export const MenuItems = [
    { url: '/', label: 'Accueil' },
    { url: '/q/reflexions', label: 'Réflexions' },
    { url: '/q/actualites', label: 'Actualités' },
    { url: '/r/livres', label: 'Livres' },
    { url: '/s/atelier', label: 'Travail en atelier' },
    { url: '/s/biographie', label: 'Biographie' },
    { url: '/s/contact', label: 'Contact' },
];

export const PrivItems = [
    { url: '/all', label: 'Tous les articles' },
    // We do not handle user anymore... 
    // TODO: reimplement 
    // { url: '/u', label: 'Utilisateurs' },
];

export const KnownTags = [
    { key: "actualites", label: "Actualités" },
    { key: "reflexions", label: "Réflexions" },
    { key: "livres", label: "Livres" }
];

// TODO 
// export const dataPath = getPath();

