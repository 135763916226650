import React from 'react';
import { connect } from 'react-redux';

import apiServer, { publicServer } from '../../utils/backend';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import AuxWrapper from '../../hoc/AuxWrapper/AuxWrapper';

import ReactMarkdown from 'react-markdown';
import { Button, Card, CardMedia, Icon } from '@mui/material';
import PostInfo from './PostInfo';
import { withStyles } from 'tss-react/mui';

// import Comments from '../comment/Comments';
import customCss from './blog.module.css';
import { getPath } from '../../App';

const postStyles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    media: {
        height: '200px',
        paddingTop: '2%', // WTF
    },
});

class Layout extends React.Component {
    render() {
        const { classes, post } = this.props;
        return (
            <div className={customCss.Post}>
                <Card>
                    <CardMedia
                        className={classes.media}
                        image={getPath(post.hero)}
                    />
                    {/* <CardTitle>{post.title}</CardTitle>  */}
                </Card>
                <div className={customCss.SubCard}>
                    <h1>{post.title}</h1>
                    <div className={customCss.Desc}>{post.desc}</div>
                    <PostInfo tags={post.tags} date={post.publishedAt} author={post.author} />
                    <ReactMarkdown className={customCss.Body}   >
                        {post.body}
                    </ReactMarkdown>
                </div>
                {/* <Divider />
                <div className={customCss.SubCard}>
                    <Comments postId={post.slug} />
                </div> */}
            </div>
        );
    }
}

const PostLayout = withStyles(Layout, postStyles);

class Post extends React.Component {
    state = {
        loadedPost: null,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData() {
        // const cId = this.props.match.params.id;
        const cId = this.props.postId;
        if (cId) {
            if (!this.state.loadedPost || this.state.loadedPost.slug !== cId) {
                if (this.props.isAuth) {
                    let options = { headers: { 'Authorization': this.props.token } };
                    apiServer.get('/posts/' + cId, options).then(response => {
                        this.setState({ loadedPost: response.data.post });
                    });
                } else {
                    let options = {};
                    publicServer.get('/posts/' + cId, options).then(response => {
                        this.setState({ loadedPost: response.data.post });
                    });
                }
            }
        }
    }

    newPostHandler = () => {
        //this.props.history.push('/p/edit');
        this.props.onClick('/p/edit');
    }

    editPostHandler = (id) => {
        this.props.onClick('/p/edit/' + id);
        //this.props.history.push('/p/edit/' + id);
    };

    deletePostHandler = (id) => {
        if (window.confirm('Are you sure you want to completely remove this post?')) {
            var options = { headers: { 'Authorization': this.props.token } };
            apiServer.delete('/posts/' + id, options).then(response => {
                this.props.onBack();
                //this.props.history.goBack();
            });
        }
    };

    canEdit = () => {
        return this.props.userRoles.includes("EDITOR") || this.props.userRoles.includes("MODERATOR");
    }

    canDelete = () => {
        return this.props.userRoles.includes("MODERATOR") || this.props.userId === this.state.loadedPost.authorId;
    }

    getEditBtns = (id) => {
        let btns = null;
        if (this.canEdit()) {
            btns = (
                // TODO simplify and factorize this
                <div>
                    <ul className={customCss.SideButtons} >
                        <li key="add" >
                            <Button onClick={this.newPostHandler}>
                                <Icon color="primary">add_circle</Icon>
                            </Button>
                        </li>
                        <li key="edit" >
                            <Button onClick={() => this.editPostHandler(id)}>
                                <Icon color="primary">edit_circle</Icon>
                            </Button>
                        </li>
                        {this.canDelete() ? (
                            <li key="delete" >
                                <Button onClick={() => this.deletePostHandler(id)} >
                                    <Icon color="secondary">delete_circle</Icon>
                                </Button>
                            </li>
                        ) : null}
                    </ul>
                </div>
            );
        }
        return btns;
    }

    render() {
        console.log('#### rendering one post, props:', this.props)
        let post = <p style={{ textAlign: 'center' }}>Please select a Post!</p>;
        if (this.props.postId) {
            post = <p style={{ textAlign: 'center' }}>Loading...</p>;
        }
        if (this.state.loadedPost) {
            // if (this.state.loadedPost.tags.indexOf("Video") !== -1) {

            //     // TODO transmit correct adress via navigate to
            //     post = <Redirect to={"/v/" + this.props.match.params.id} />;
            // } else {
            post = (
                <AuxWrapper>
                    {this.getEditBtns(this.state.loadedPost.slug)}
                    <PostLayout post={this.state.loadedPost} />
                </AuxWrapper>
            );
            // }
        }
        return post;
    }
}

const mapStateToProps = state => {
    return {
        token: state.oidc.user ? state.oidc.user.id_token : null,
        isAuth: state.oidc.user != null,
        userRoles: state.auth.userRoles,
    };
};

export default withErrorHandler(connect(mapStateToProps)(Post), apiServer);
