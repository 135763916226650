import apiServer, { publicServer } from '../../utils/backend';

import { POSTS_FETCH_START, POSTS_FETCH_SUCCESS, POSTS_FETCH_ERROR } from './actionTypes';



const fetchStart = (queryString, displayType) => {
    return {
        type: POSTS_FETCH_START,
        payload: { query: queryString, displayType: displayType }
    };
};

const fetchSuccess = (posts) => {
    return {
        type: POSTS_FETCH_SUCCESS,
        posts: posts
    };
};

const fetchError = (error) => {
    return {
        type: POSTS_FETCH_ERROR,
        error: error
    };
};

export const fetchPosts = (queryString, displayType) => {
    return (dispatch, getState) => {

        let currState = getState()
        if (currState.posts.posts) {
            // if (queryString === "") {
            //     return
            // } else 
            if (queryString === currState.posts.query && currState.posts.error === null) {
                // console.log('List is already showing', queryString, ', nothing to do')
                return
            }
        }
        dispatch(fetchStart(queryString, displayType))
        let uri = queryString && queryString !== '' ? '/posts?' + queryString : '/posts';

        console.log('About to fetch posts', queryString, currState.oidc.user)

        if (currState.oidc.user && currState.oidc.user.id_token) {
            var options = { headers: { 'Authorization': currState.oidc.user.id_token } };
            return apiServer.get(uri, options)
                .then(response => {
                    console.log('Fetch posts success:', response.data)
                    dispatch(fetchSuccess(response.data.posts));
                }).catch(error => {
                    console.log('Fetch posts failed:', error)
                    dispatch(fetchError(error));
                });
        } else {
            return publicServer.get(uri)
                .then(response => {
                    console.log('Fetch posts success:', response.data)
                    dispatch(fetchSuccess(response.data.posts));
                }).catch(error => {
                    console.log('Fetch posts failed:', error)
                    dispatch(fetchError(error));
                });
        };
    };
};
