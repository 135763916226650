import { createUserManager } from 'redux-oidc-2';
import { Log } from 'oidc-client-ts';

import getConfig from './config'

Log.logger = console;
Log.level = Log.DEBUG;

const userManager = createUserManager(getConfig());

export default userManager;
