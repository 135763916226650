import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import classes from './blog.module.css';

const postInfo = (props) => {
    let tags;
    if (props.tags) {
        tags = props.tags.split(' ').map(tag => {
            return (<NavLink key={tag} to={'/q/' + tag} className="TextLink">#{tag}</NavLink>);
        });
    }
    let dateStr = moment(props.date).format('DD.MM.YYYY');

    // const dateStr = moment(props.date*1000).format('DD MMM. YYYY');
    
    // TODO: plug this back when author is fixed.
    // {props.author}, le {dateStr} | {tags}

    return (
        <div className={classes.PostInfo}>
            <div className={classes.Author}>le {dateStr} | {tags}</div>
        </div>
    );
}

export default postInfo;
