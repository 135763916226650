import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createOidcMiddleware, { OidcProvider, loadUser } from 'redux-oidc-2';

import registerServiceWorker from './registerServiceWorker';
import userManager from './utils/userManager'
import rootReducer from './store/reducers'
import App from './App';

import './vitrnx.css';
import { KnownTags } from './specific';

// Enable Redux devtool in chrome only in dev mode
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Initialise redux
const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(createOidcMiddleware(userManager), thunk)));

const app = (
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
                <App />
        </OidcProvider>
    </Provider>
)

// if (window.location.pathname === '/silent-renew') {
// new userManager.signinSilentCallback();
// } else {
// ReactDOM.render(app, document.getElementById('vitrnx'));
// }

export const withKnownTags = () => {
    return KnownTags;
}

// React 18
const container = document.getElementById('vitrnx');
const root = createRoot(container); 
root.render(app);

registerServiceWorker();

// const mapStateToProps = state => {
//   return {
//     isAuth: state.oidc.user != null,
//     userRoles: null,
//     dname: state.oidc.user !== null ? state.oidc.user.profile.name : "Anonymous",
//   };
// };

// // const mapDispatchToProps = dispatch => {
// //   return {
// //     onTryAutoSignup: () => dispatch(actions.authCheckState()),
// //   };
// // };

// Try to retrieve a session
loadUser(store, userManager);
