import React from 'react';

import NavigationItem from '../components/navigation/NavigationItem'
import SideDrawer from '../components/navigation/SideDrawer';

import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';


import AccountCircle from '@mui/icons-material/AccountCircle';

const styles = {
    root: {
        flexGrow: 0,
    },
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        ['@media (max-width:949px)']: { // eslint-disable-line no-useless-computed-key
            height: '0px',
        }
    },
    login: {
        top: -3,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },

    fabButton: {
        position: 'absolute',
        opacity: 0.8,
        zIndex: 1,
        top: -30,
        right: "2%",
        margin: '0 auto',
    },
};

class MenuAppBar extends React.Component {
    state = {
        anchorEl: null,
    };

    render() {
        const { className, classes, menuItems, privItems, isAuth, userRoles, onToggleLogin } = this.props;

        // Note: cannot use <Link> here because it would always make a round trip to the server...
        var links = menuItems.map(
            item => (
                <Typography key={item.url} variant="body1">
                    <NavigationItem link={item.url} >{item.label}</NavigationItem>
                </Typography>
            )
        );

        var privateMenu = privItems.map(
            item => (
                <Typography key={item.url} variant="body1">
                    <NavigationItem link={item.url} >{item.label}</NavigationItem>
                </Typography>
            )
        );

        return (
            <div className={[classes.root, className].join(' ')}>
                <AppBar position="static" className={classes.appBar} >
                    <Toolbar variant="dense">
                        <div className={classes.grow} color="inherit" />
                        {/* Main menu for large enough screens */}
                        <Hidden smDown>
                            {links}
                            <IconButton onClick={onToggleLogin} className={classes.login} color={isAuth ? "primary" : "default"}>
                                <AccountCircle />
                            </IconButton>
                            {privateMenu}
                        </Hidden>

                        {/* Popup menu for smaller screens */}
                        <Hidden mdUp>
                            <SideDrawer navItems={menuItems} privItems={privItems} onToggleLogin={onToggleLogin} isAuth={isAuth} userRoles={userRoles} className={classes.fabButton} />
                        </Hidden>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(MenuAppBar, styles);
