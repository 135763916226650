import React from 'react';

import { Typography  } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import classNames from 'classnames';

import { highlightColor, topRightColor, bottomLeftColor } from '../constants'
import {Title, Subtitle} from '.'

const styles = {
    root: {
        background: 'linear-gradient(to top right, ' + bottomLeftColor + ', ' + topRightColor + ')',
        borderRadius: 0,
        padding: '1em 2em 0.3em 2em',
        margin: '0em',
    },
    title: {
        color: highlightColor,
        padding: '0.2em 0em',
        margin: '0px',
        ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1.5em',
            fontWeight: '450'
        }
    },
    subtitle: {
        color: highlightColor,
        padding: '0em 0em 0em 0.1em',
        ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1.2em',
            fontWeight: '400'
        }
    },
};

function Header(props) {
    const { classes, className } = props;
    return (
        <header className={classNames(classes.root, className)}>
            <Typography variant="h2" gutterBottom className={classNames(classes.title)}>{Title}</Typography>
            <Typography variant="h5" gutterBottom className={classNames(classes.subtitle)}>{Subtitle}</Typography>
        </header>
    );
}

export default withStyles(Header, styles);
