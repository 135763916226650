import React from 'react';
import moment from 'moment';

import { Avatar, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import { getPath } from '../../App';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const PostListItem = (props) => {

  const { classes, post, postSelected } = props

  let ddate = post.publishedAt ? post.publishedAt : post.updatedAt;
  let dateStr = moment(ddate, 'YYYY-MM-DDThh:mm:ssZ', false).format('DD/MM/YYYY');

  return (
    <ListItem onClick={postSelected}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={getPath(post.thumb)} />
      </ListItemAvatar>
      <ListItemText
        style={{ textAlign: 'left' }}
        primary={post.title + ', le ' + dateStr + ', ' + post.tags}
        secondary={post.desc}
      />
    </ListItem>
  );
}

export default withStyles(PostListItem, styles);