// USER MANAGEMENT
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// POSTS
export const POST_CREATE = 'POST_CREATE';
export const POST_READ = 'POST_READ';
export const POST_UPDATE = 'POST_UPDATE';
export const POST_START_SAVING = 'POST_START_SAVING';
export const POST_SAVE_SUCCESS = 'POST_SAVE_SUCCESS';
export const POST_SAVE_FAILURE = 'POST_SAVE_FAILURE';
export const POST_STOP_EDITING = 'POST_STOP_EDITING';

export const POSTS_FETCH_START = 'POSTS_FETCH_START';
export const POSTS_FETCH_SUCCESS = 'POSTS_FETCH_SUCCESS';
export const POSTS_FETCH_ERROR = 'POSTS_FETCH_ERROR';

// UI 
export const UI_OPEN_COMING_DIALOG = 'UI_OPEN_COMING_DIALOG';
export const UI_CLOSE_COMING_DIALOG = 'UI_CLOSE_COMING_DIALOG';

export const AUDIO_TOGGLE_PLAYING_STATUS = 'AUDIO_TOGGLE_PLAYING_STATUS';
export const AUDIO_SKIP_TRACK = 'AUDIO_SKIP_TRACK';
export const AUDIO_FORCE_PAUSE = 'AUDIO_FORCE_PAUSE';
