import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { getPath } from '../../App';

const styles = {
  card: {
    maxWidth: 345,
    margin: '1em'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
};

const PostCard = (props) => {

  const { classes } = props;

  return (
    <div>
      <Card className={classes.card} onClick={props.clicked}>
        <CardMedia
          className={classes.media}
          image={getPath( props.thumb)}
        // overlay does not work?? overlay={ <CardTitle title={props.title}/>}
        // gutterBottom 
        />
        <CardContent style={{ textAlign: 'left' }}>
          <Typography variant="h5" >
            {props.title}
          </Typography>
          <Typography variant="body1">
            {props.desc}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

PostCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(PostCard, styles);